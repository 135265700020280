import { createRouter, createWebHistory } from "vue-router";

import { hasToken } from "../utilities/tools";
import { checkLoginedUserPrivilege } from "../utilities/usePrivilege";

// Auth Routes
const authChildRoutes = (prefix) => [
  {
    path: "",
    name: prefix + ".login",
    meta: { auth: false, name: "LOGIN" },
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "reset-password",
    name: prefix + ".reset-password",
    meta: { auth: false, name: "RESET_PASSWORD" },
    component: () => import("@/views/auth/default/ResetPassword.vue"),
  },
];

// Dashboard Routes
const dashboardChildRoutes = (prefix) => [
  {
    path: "dashboard",
    name: prefix + ".dashboard",
    meta: { auth: false, name: "DASHBOARD", isBanner: true },
    component: () => import("@/views/dashboards/IndexPage.vue"),
  },
];

// Users Routes
const userChildRoutes = (prefix) => [
  {
    path: "user-profile",
    name: prefix + ".user-profile",
    meta: { auth: false, name: "USER_PROFILE", isBanner: true },
    component: () => import("@/views/user/ProfilePage.vue"),
  },
];

// Integeration Info Routes
const integerationInfoChildRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: { auth: false, name: "USER_PROFILE", isBanner: true },
    component: () => import("@/views/integration-info/InfoPage.vue"),
  },
];

// Member Settings
const memberSettingsRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "MEMBER_SETTINGS",
      isBanner: false,
      requireAuth: true,
      privStr: "LICENSEE_DIRECT_MEMBER_SETTINGS_LIST",
    },
    component: () => import("@/views/member-settings/ListPage.vue"),
  },
];

// Event List Routes
const eventListChildRoutes = (prefix) => [
  {
    path: "list-event-result",
    name: prefix + ".list-event-result",
    meta: {
      auth: true,
      name: "EVENT_RESULT",
      isBanner: false,
      requireAuth: true,
      privStr: "EVENT_RESULT_LIST",
    },
    component: () => import("@/views/event-result/ListEventResult.vue"),
  },
];

// Bet List Routes
const betListChildRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "BET",
      isBanner: false,
      requireAuth: true,
      privStr: "BET_LIST",
    },
    component: () => import("@/views/bet-list/ListPage.vue"),
  },
  {
    path: "list-outstanding-bet",
    name: prefix + ".list-outstanding-bet",
    meta: {
      auth: true,
      name: "PENDING_AND_OUTSTANDING_BET",
      isBanner: false,
      requireAuth: true,
      privStr: "BET_LIST",
    },
    component: () => import("@/views/bet-list/ListOutstandingBet.vue"),
  },
  {
    path: "list-pending-bet",
    name: prefix + ".list-pending-bet",
    meta: {
      auth: true,
      name: "PENDING_BET",
      isBanner: false,
      requireAuth: true,
      privStr: "BET_LIST",
    },
    component: () => import("@/views/bet-list/ListPendingBet.vue"),
  },
];

// Settlement Report Routes
const settlementReportChildRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "SETTLEMENT_REPORT",
      isBanner: false,
      requireAuth: true,
      privStr: "SETTLEMENT_REPORT",
    },
    component: () => import("@/views/settlement-report/SearchCriteriaPage.vue"),
  },
  {
    path: "list-direct-mem",
    name: prefix + ".list-direct-mem",
    meta: {
      auth: true,
      name: "DIRECT_MEMBER_SETTLEMENT_REPORT",
      isBanner: false,
      requireAuth: true,
      privStr: "SETTLEMENT_REPORT",
    },
    component: () =>
      import("@/views/settlement-report/DirectMemberSearchCriteriaPage.vue"),
  },
];

// Settlement Report Routes
const betSummaryReportChildRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "BET_SUMMARY_REPORT",
      isBanner: false,
      requireAuth: true,
      privStr: "BET_SUMMARY_REPORT",
    },
    component: () =>
      import("@/views/bet-summary-report/SearchCriteriaPage.vue"),
  },
];

// Licensee
const licenseeRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "LICENSEE",
      isBanner: false,
      requireAuth: true,
      privStr: "LICENSEE_LIST",
    },
    component: () => import("@/views/licensee/ListPage.vue"),
  },
];

// Member
const memberRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "MEMBER",
      isBanner: false,
      requireAuth: true,
      privStr: "MEMBER_LIST",
    },
    component: () => import("@/views/member/ListPage.vue"),
  },
];

// Member Transaction
const memberTransactionRoutes = (prefix) => [
  {
    path: "member-local-wallet-transaction",
    name: prefix + ".member-local-wallet-transaction",
    meta: {
      auth: true,
      name: "MEMBER_TRANSACTION",
      isBanner: false,
      requireAuth: true,
      privStr: "MEMBER_TRANSACTION_LIST",
    },
    component: () =>
      import("@/views/transaction/ListMemberLocalWalletTransactionPage.vue"),
  },
  {
    path: "member-external-wallet-transaction",
    name: prefix + ".member-external-wallet-transaction",
    meta: {
      auth: true,
      name: "DIRECT_MEMBER_SETTLEMENT_REPORT",
      isBanner: false,
      requireAuth: true,
      privStr: "MEMBER_TRANSACTION_LIST",
    },
    component: () =>
      import("@/views/transaction/ListMemberExternalWalletTransactionPage.vue"),
  },
];

// Licensee User
const licenseeUserRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "licensee-user",
      isBanner: false,
      requireAuth: true,
      privStr: "LICENSEE_USER_LIST",
    },
    component: () => import("@/views/licensee-user/ListPage.vue"),
  },
];

// Licensee User Group
const licenseeUserGroupRoutes = (prefix) => [
  {
    path: "list",
    name: prefix + ".list",
    meta: {
      auth: true,
      name: "LICENSEE_USER_GROUP",
      isBanner: false,
      requireAuth: true,
      privStr: "LICENSEE_USER_GROUP_LIST",
    },
    component: () => import("@/views/licensee-user-group/ListPage.vue"),
  },
];

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: "404",
    name: prefix + ".404",
    meta: { auth: true, name: "ERROR_404", isBanner: true },
    component: () => import("@/views/errors/Error404Page.vue"),
  },
  {
    path: "500",
    name: prefix + ".500",
    meta: { auth: true, name: "ERROR_500", isBanner: true },
    component: () => import("@/views/errors/Error500Page.vue"),
  },
  {
    path: "maintenance",
    name: prefix + ".maintenance",
    meta: { auth: true, name: "MAINTENANCE", isBanner: true },
    component: () => import("@/views/errors/MaintenancePage.vue"),
  },
];

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../layouts/guest/BlankLayout.vue"),
    children: authChildRoutes("auth"),
  },
  // Dashboard Pages
  {
    path: "/",
    name: "dashboard",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: dashboardChildRoutes("default"),
  },
  // Event Result List Pages
  {
    path: "/event-list",
    name: "event-list",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: eventListChildRoutes("event-list"),
  },
  // Bet List Pages
  {
    path: "/bet-list",
    name: "bet-list",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: betListChildRoutes("bet-list"),
  },
  // Settlement Report List Pages
  {
    path: "/settlement-report",
    name: "settlement-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: settlementReportChildRoutes("settlement-report"),
  },
  // Bet Summary Report List Pages
  {
    path: "/bet-summary-report",
    name: "bet-summary-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: betSummaryReportChildRoutes("bet-summary-report"),
  },
  {
    path: "/company-ticket-report",
    name: "company-ticket-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: [
      {
        path: "list",
        name: "company-ticket-report.list",
        meta: {
          auth: true,
          name: "COMPANY_TICKET_REPORT",
          isBanner: false,
          requireAuth: true,
          privStr: "COMPANY_TICKET_REPORT",
        },
        component: () =>
          import("@/views/company-ticket-report/SearchCriteriaPage.vue"),
      },
    ],
  },
  {
    path: "/company-settlement-report",
    name: "company-settlement-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: [
      {
        path: "list",
        name: "company-settlement-report.list",
        meta: {
          auth: true,
          name: "COMPANY_SETTLEMENT_REPORT",
          isBanner: false,
          requireAuth: true,
          privStr: "COMPANY_SETTLEMENT_REPORT",
        },
        component: () =>
          import("@/views/company-settlement-report/SearchCriteriaPage.vue"),
      },
    ],
  },
  {
    path: "/company-usdt-settlement-report",
    name: "company-usdt-settlement-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: [
      {
        path: "list",
        name: "company-usdt-settlement-report.list",
        meta: {
          auth: true,
          name: "COMPANY_USDT_SETTLEMENT_REPORT",
          isBanner: false,
          requireAuth: true,
          privStr: "COMPANY_USDT_SETTLEMENT_REPORT",
        },
        component: () =>
          import(
            "@/views/company-usdt-settlement-report/SearchCriteriaPage.vue"
          ),
      },
    ],
  },
  // multiple transfer report
  {
    path: "/lic-multiple-transfer-report",
    name: "lic-multiple-transfer-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: [
      {
        path: "list",
        name: "lic-multiple-transfer-report.list",
        meta: {
          auth: true,
          name: "LICENSEE_MULTIPLE_TRANSFER_REPORT",
          isBanner: false,
          requireAuth: true,
          privStr: "LICENSEE_MULTIPLE_TRANSFER_REPORT",
        },
        component: () =>
          import("@/views/lic-multiple-transfer-report/ListPage.vue"),
      },
    ],
  },
  {
    path: "/direct-mem-multiple-transfer-report",
    name: "direct-mem-multiple-transfer-report",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: [
      {
        path: "list",
        name: "direct-mem-multiple-transfer-report.list",
        meta: {
          auth: true,
          name: "DIRECT_MEMBER_MULTIPLE_TRANSFER_REPORT",
          isBanner: false,
          requireAuth: true,
          privStr: "DIRECT_MEMBER_MULTIPLE_TRANSFER_REPORT",
        },
        component: () =>
          import("@/views/direct-mem-multiple-transfer-report/ListPage.vue"),
      },
    ],
  },
  // Licensee Pages
  {
    path: "/licensee",
    name: "licensee",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: licenseeRoutes("licensee"),
  },
  // Member Pages
  {
    path: "/member",
    name: "member",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: memberRoutes("member"),
  },
  // Member Transaction Pages
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: memberTransactionRoutes("transaction"),
  },
  // Licensee User Pages
  {
    path: "/licensee-user",
    name: "licensee-user",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: licenseeUserRoutes("licensee-user"),
  },
  // Licensee User Group Pages
  {
    path: "/licensee-user-group",
    name: "licensee-user-group",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: licenseeUserGroupRoutes("licensee-user-group"),
  },
  // User Pages
  {
    path: "/user",
    name: "user",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: userChildRoutes("user"),
  },
  // Member Settings Pages
  {
    path: "/member-settings",
    name: "member-settings",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: memberSettingsRoutes("member-settings"),
  },
  // User Pages
  {
    path: "/integration-info",
    name: "integration-info",
    component: () => import("../layouts/DefaultLayout.vue"),
    children: integerationInfoChildRoutes("integration-info"),
  },
  // Errors Pages
  {
    path: "/errors",
    name: "errors",
    component: () => import("../layouts/guest/BlankLayout.vue"),
    children: errorRoutes("errors"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "pages-not-found",
    redirect: "/errors/404",
    hidden: true,
  },
];

const router = createRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to) => {
  if (!hasToken() && to.meta["requireAuth"]) {
    return { path: "/" };
  } else if (to.meta["requireAuth"]) {
    if (!checkLoginedUserPrivilege(to.meta["privStr"])) {
      return { path: "/errors/404" };
    }
  }
});

export default router;
